import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/SEO';
import SectionIntro from '../../../components/SectionIntro';
import {getImage, StaticImage} from 'gatsby-plugin-image';
import {Link, useStaticQuery, graphql} from 'gatsby';
import Button from '../../../components/Button';

// Background images
import {convertToBgImage} from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import russelCurious from '../../../images/product/russel-curious.svg';
import {TitleBordered} from '../../../components/utils';

const sectionTextClass = `mb-12 text-lg text-left xl:leading-loose`;
const backgroundTextClass = `mb-6 text-lg text-center xl:leading-loose text-white`;

const Jit = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }

      placeholderImage02: file(relativePath: {eq: "bg/bg-section-header.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
      placeholderImage03: file(relativePath: {eq: "bg/rebrand/bg-main-middle.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage, placeholderImage02, placeholderImage03} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const image02 = getImage(placeholderImage02);
  const bgImage02 = convertToBgImage(image02);
  const image03 = getImage(placeholderImage03);
  const bgImage03 = convertToBgImage(image03);

  const dataSalDefault = {
    'data-sal-duration': '700',
    'data-sal-delay': '300',
    'data-sal-easing': 'ease',
  };

  return (
    <Layout
      seo={
        <Seo
          title="Just-In-Time (JIT) | Trustle"
          description="Keeping track of who has access across all your cloud-based services far exceeds human capacity."
        />
      }
    >
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
      >
        <section className="pt-16 pb-8">
          <div className="max-w-5xl px-5 pt-0 mx-auto text-center text-white">
            <SectionIntro hero="Product" title="Just-In-Time Access" description="(JIT)" isPageHeader />
          </div>
          <div className="mb-8 md:mb-16 text-sm w-[250px] mx-auto font-bold text-center">
            <Link to="/demo">
              <Button label="Book a Demo" />
            </Link>
          </div>
        </section>

        <section>
          <div className="pt-0 mx-auto mb-20 text-center text-white max-w-[1920px]">
            <div className="flex flex-col justify-between xl:flex-row ">
              <div className="xl:w-1/2">
                <div className="">
                  <StaticImage
                    src="../../../images/product/product-jit-01.jpg"
                    alt="trustle app"
                    className="w-full mb-8"
                    quality={100}
                  />
                </div>
              </div>
              <div className="flex flex-col items-start px-5 pt-12 xl:w-1/2 2xl:pl-8 text-blue">
                <div className="mx-auto xl:max-w-2xl">
                  <div className="text-left">
                    <TitleBordered title="Do you know who has access to what?" align="left" />
                  </div>
                  <p className={`${sectionTextClass} !text-black !opacity-60`}>
                    Keeping track of who has access across all your cloud-based services far exceeds human capacity.
                  </p>
                  <div className="mb-4 text-xl font-bold text-left md:text-2xl">Security teams are overwhelmed, </div>
                  <p className={`${sectionTextClass} !text-black !opacity-60`}>
                    approving access without context. Increasing the risk of approving unusual or suspicious activity.
                  </p>
                  <div className="mb-4 text-xl font-bold text-left md:text-2xl">
                    Approval workflows for your full-time employees are already a struggle,{' '}
                  </div>
                  <p className={`${sectionTextClass} !text-black !opacity-60`}>
                    but it becomes even more burdensome when you include contractors, Customer-based accounts, and
                    System-based accounts.
                  </p>
                  <div className="flex items-center text-xl font-bold md:text-2xl ">
                    <span className="mb-[24px] mr-1 md:mr-2">That’s where </span>
                    <TitleBordered title=" Trustle comes in." fontSizeClass="text-xl md:text-2xl" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage02}
        preserveStackingContext
        className="bg-top bg-cover"
      >
        <section className="mb-20 ">
          <div className="px-5 pt-16 pb-12 mx-auto  text-center text-white max-w-[1360px] ">
            <div className="flex flex-col items-center justify-between pb-16 xl:items-baseline xl: xl:flex-row">
              <div className="flex flex-col md:max-w-[400px] items-center mb-8 xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Visibility and Control</div>
                <p className={backgroundTextClass}>
                  Identify who has access to what in your environment, with context into access policies and approvals.
                </p>
              </div>

              <div className="flex flex-col md:max-w-[400px] items-center mb-8 xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Surface Risks</div>
                <p className={backgroundTextClass}>
                  Detect identity risks - shadow users, overprivileged accounts, unnecessary standing access - and
                  mitigate potential issues.
                </p>
              </div>

              <div className="flex flex-col md:max-w-[400px] items-center mb-8 xl:mb-0">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Streamline Access</div>
                <p className={backgroundTextClass}>
                  Enable seamless risk-based JIT access workflows, with built in expiration and revocation.
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage03}
        preserveStackingContext
      >
        <section>
          <div className="px-5 pt-0 mx-auto mb-20 text-center text-white max-w-[1360px]">
            <div className="mb-20">
              <TitleBordered title="Trustle’s JIT features" />
            </div>

            <div className="flex flex-col items-center justify-between mb-16 lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]" data-sal="slide-right" {...dataSalDefault}>
                <StaticImage
                  src="../../../images/product/product-jit-a1.png"
                  alt="trustle app"
                  className="w-full"
                  quality={100}
                />
              </div>
              <div
                className="max-w-[658px] mb-16 flex flex-col items-start text-blue"
                data-sal="slide-left"
                {...dataSalDefault}
              >
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Proactive Access Management</div>
                  <p className={sectionTextClass}>
                    <b>
                      With Trustle, you can take a distributed, proactive approach to access management and stay ahead
                      of potential threats to your cloud environment with just-in-time access.
                    </b>
                  </p>
                  <p className={`${sectionTextClass} !text-black !opacity-60`}>
                    By identifying and addressing risks, you can reduce the likelihood of accidental exposures, ensuring
                    that your organization remains secure.
                  </p>

                  <p className={sectionTextClass}></p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between mb-16 lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]" data-sal="slide-right" {...dataSalDefault}>
                <StaticImage
                  src="../../../images/product/product-jit-b1.png"
                  alt="trustle app"
                  className="w-full"
                  quality={100}
                />
              </div>
              <div
                className="max-w-[658px] flex flex-col items-start text-blue"
                data-sal="slide-left"
                {...dataSalDefault}
              >
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Automated Access Assessment & Reporting</div>

                  <p className={sectionTextClass}>
                    <b>
                      Trustle helps you approach risk proactively, providing compliance and access history reports to
                      keep key stakeholders informed.
                    </b>
                  </p>
                  <p className={`${sectionTextClass} !text-black !opacity-60`}>
                    For management and systems owners, these reports facilitate policy-related discussions and changes,
                    making it easier to stay on top of security. For auditors, these reports provide an automated method
                    of generating evidence of regulatory compliance.<br></br> Our automated assessment provides a
                    holistic view of each SaaS and Cloud environment, identifying risks and misconfigurations, and our
                    machine learning technology detects anomalous and potentially threatening access requests, in
                    addition to well-known risks.
                  </p>

                  <div className="mb-12 text-xl font-bold text-left md:text-xl">
                    <b>This helps you stay one step ahead of cyber attacks.</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between lg:flex-row">
              <div className="mb-8 lg:mb-0 max-w-[550px]" data-sal="slide-right" {...dataSalDefault}>
                <StaticImage
                  src="../../../images/product/product-jit-c1.png"
                  alt="trustle app"
                  className="w-full"
                  quality={100}
                />
              </div>
              <div
                className="max-w-[658px] flex flex-col items-start text-blue"
                data-sal="slide-left"
                {...dataSalDefault}
              >
                <div className="mb-8 text-left">
                  <div className="mb-4 text-xl font-bold md:text-2xl">Intelligent Provisioning Workflows </div>
                  <p className={`${sectionTextClass} !text-black !opacity-60 !mb-8`}>
                    Trustle also offers flexible and convenient methods for risk remediation. Once you understand your
                    security posture and attack surface, Trustle provides multiple options for automatic access-related
                    mitigation.
                  </p>
                  <p className={`${sectionTextClass}`}>
                    <b>Trustle is quick and easy to set up, taking only minutes instead of weeks.</b>
                  </p>
                  <div className="text-xl font-bold text-left md:text-xl">
                    We value efficiency, functionality, and security, so you don't have to compromise.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>

      {/* Banner Russel */}
      <section className="py-28 ">
        <BackgroundImage Tag="section" {...bgImage02} preserveStackingContext>
          <div className="relative flex flex-col items-center justify-center w-full pt-12 pb-8 mb-8 text-white md:flex-row lg:max-w-4xl lg:mx-auto filter drop-shadow-2xl">
            <div className="relative flex flex-col-reverse items-center justify-center mt-4 md:ml-8 md:mt-0">
              <div>
                <h3 className="pb-4 text-3xl font-bold text-center md:text-2xl">Schedule a demo today!</h3>
                <div className="px-4 text-center pb-7 text-md md:text-lg">
                  Get an inside look at Trustle. Schedule a demo with us today.
                </div>
                <div className="w-full px-4 mx-auto text-center">
                  <Link to="/demo">
                    <Button label="Schedule Demo" variant="secondary" />
                  </Link>
                </div>
              </div>
              <div className="relative flex flex-col justify-between h-full md:absolute md:top-24 md:-left-8">
                <img
                  src={russelCurious}
                  alt="Russell Trustle curious"
                  className="max-w-[80px] md:max-w-[110px] lg:max-w-[170px]"
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
    </Layout>
  );
};
export default Jit;
